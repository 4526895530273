<template>
  <div class="page-content">
    <div class="content">
      <div>
        <div class="title">{{ $t('ni-zai-na-li-song-huo') }}</div>
        <p>
          {{
            $t(
              'yao-le-jie-wo-men-shi-fou-yun-song-dao-nin-suo-zai-de-di-qu-qing-zai-nin-yao-gou-mai-de-shang-pin-shang-shu-ru-nin-de-you-zheng-bian-ma-chan-pin-zhi-jie-cong-wo-men-de-gong-ying-shang-chu-fa-huo-wo-men-de-quan-qiu-cang-ku-bian-bu-112-ge-guo-jia-di-qu'
            )
          }}
        </p>
      </div>
      <div>
        <h2 class="title">{{ $t('qu-na-li-qu') }}</h2>
        <p>
          {{
            $t(
              'wo-men-zai-appname-shang-dian-sainsburys-chao-shi-he-quan-qiu-cang-ku-de-shou-ji-dian-quan-tian-hou-247-yun-ying',
              [appName]
            )
          }}
        </p>
        <p>
          {{
            $t(
              'dui-yu-ke-yong-yu-kuai-su-tong-dao-jiao-fu-de-wu-pin-qing-gen-ju-nin-suo-zai-de-wei-zhi-cong-duo-ge-shi-jian-duan-zhong-jin-hang-xuan-ze-ding-dan-jiang-song-huo-shang-men-bing-cun-fang-zai-an-quan-ke-kao-de-di-fang'
            )
          }}
        </p>
        <p>
          {{
            $t(
              'ying-ge-lan-su-ge-lan-he-wei-er-shi-ti-gong-da-xing-he-ben-zhong-wu-pin-xu-yao-liang-ren-huo-duo-ren-ban-yun-de-wu-pin-li-ru-sha-fa-he-chuang-yin-ci-wo-men-ke-yi-jiang-ta-men-an-quan-di-yun-song-dao-nin-xuan-ze-de-ren-he-di-fang-wo-men-you-hao-de-si-ji-zhi-yi-jiang-zai-tong-yi-tian-yu-nin-lian-xi-tao-lun-song-huo-xi-jie-ru-guo-nin-zheng-zai-zi-wo-ge-li-qing-gao-zhi-wo-men-yi-bian-wo-men-zhong-xin-an-pai-nin-de-jiao-huo-ri-qi'
            )
          }}
        </p>
        <p>
          {{
            $t(
              'wo-men-mu-qian-dui-da-jian-wu-pin-de-jiao-fu-you-hen-gao-de-xu-qiu-ru-guo-nin-de-jiao-huo-yan-chi-wo-men-hui-jin-kuai-gei-nin-fa-song-xiao-xi-wo-men-zheng-zai-nu-li-wei-wo-men-de-ke-hu-ti-gong-zui-hao-de-fu-wu-gan-xie-nin-yi-zhi-yi-lai-de-nai-xin-he-zhi-chi'
            )
          }}
        </p>
      </div>
      <div>
        <h2 class="title">{{ $t('chang-jian-wen-ti') }}</h2>
        <p style="font-weight: 600">
          {{
            $t(
              'ru-guo-wo-tong-shi-ding-gou-da-jian-he-xiao-jian-shang-pin-zen-mo-ban'
            )
          }}
        </p>
        <p>
          {{
            $t(
              'jiang-ta-men-song-dao-nin-shou-zhong-de-zui-kuai-fang-shi-shi-dan-du-yun-song-bu-yong-dan-xin-nin-zhi-xu-zhi-fu-yi-ci-yun-fei-qing-cha-kan-nin-de-ding-dan-que-ren-dian-zi-you-jian-yi-huo-qu-geng-duo-xin-xi'
            )
          }}
        </p>
        <p style="font-weight: 600">
          {{ $t('ru-guo-wo-bu-zai-jia-zen-mo-ban') }}
        </p>
        <p>
          {{
            $t(
              'xiao-wu-pin-jiang-liu-zai-an-quan-ke-kao-de-di-fang-zai-kan-bu-jian-de-di-fang-huo-ying-nin-de-yao-qiu-jiao-gei-nin-de-lin-ju-da-jian-huo-gui-zhong-wu-pin-xu-yao-zhao-pian-huo-qian-ming-qie-bu-neng-liu-xia-yin-ci-bi-xu-you-ren-zai-jia-ling-qu-ru-guo-nin-wu-fa-gan-dao-qing-tong-guo-bang-zhu-re-xian-lian-xi-wo-men-yi-bian-wo-men-zhong-xin-an-pai-song-huo-shi-jian-bing-xuan-ze-zui-shi-he-nin-de-ri-qi-he-shi-jian-duan'
            )
          }}
        </p>
        <p style="font-weight: 600">
          {{ $t('you-duo-shao-ren-yun-song-da-jian-wu-pin') }}
        </p>
        <p>
          {{
            $t(
              'da-jian-wu-pin-ru-sha-fa-bing-gui-deng-ke-yi-liang-ren-song-huo-wo-men-you-hao-de-si-ji-jiang-zai-song-huo-dang-tian-yu-nin-lian-xi-bing-jiang-nin-de-ding-dan-an-quan-di-song-zhi-nin-xuan-ze-de-di-dian'
            )
          }}
        </p>
        <p style="font-weight: 600">
          {{ $t('covid19-dui-jiao-fu-de-ying-xiang') }}
        </p>
        <p>
          {{
            $t(
              'wo-men-reng-ran-ti-gong-xu-duo-shang-pin-de-song-huo-fu-wu-bao-kuo-wo-men-de-you-xi-ji-yu-gou-dang-jiao-huo-ri-qi-lin-jin-shi-nin-jiang-shou-dao-wo-men-de-duan-xin-ti-xing-bing-zai-jiao-huo-qian-shou-dao-kuai-di-yuan-de-gen-zong-hao-ma'
            )
          }}
        </p>
        <p>
          {{
            $t(
              'huo-wu-jiang-zai-an-quan-qu-yu-song-huo-shang-men-da-jian-wu-pin-jiang-bei-yun-song-dao-an-quan-de-di-fang-wo-men-you-hao-de-si-ji-zhi-yi-jiang-zai-tong-yi-tian-yu-nin-lian-xi-tao-lun-da-jian-wu-pin-de-yun-shu-xi-jie-ru-guo-nin-zheng-zai-zi-wo-ge-li-qing-gao-zhi-wo-men-yi-bian-wo-men-zhong-xin-an-pai-nin-de-jiao-huo-ri-qi'
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    appName() {
      return this.$store.state.appName
    }
  }
}
</script>